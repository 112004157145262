import { useState, useContext, useEffect } from "react";
import { dispatchError } from "../../common/fx";
import { numberWithCommas } from "../../utils/formatMoney";
import { ThemeProvider } from "@mui/material/styles";
import { mainTheme } from "../../styles/MainTheme";
import { Button, CssBaseline, Container, Dialog, DialogActions, DialogContent, Skeleton, Stack } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { useSearchParams, useNavigate } from "react-router-dom";
import OptionCategories from "../../components/OptionCategories";
import {
  LineItems,
  OptionsCategory,
  OptionTypes,
  HighlandCategoryOption,
  UIContext,
  UIState
} from "../../providers/UIProvider";
import {
  downloadChangeOrderPDF,
  findBuyerBYSFAccountIdProject,
  getTemplates,
  getQuote,
  retrieveAllOptions,
  retrieveOptionCategories,
  updateQuote,
  UpdateQuoteType
} from "../../apiCalls";
import QuoteHeader from "./QuoteHeader";
import QuoteFooter from "./QuoteFooter";
import useAccessControl from "../../hooks/useAccessControl";

export default function Quote() {
  const [searchParams] = useSearchParams();
  let quoteId = searchParams.get("quoteId");
  const [isQuoteLoaded, setIsQuoteLoaded] =
    useState<boolean>(false);
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [isSummarySticky, setIsSummarySticky] = useState<boolean>(false);
  const [coAmount, setCoAmount] = useState<number>(0);
  const [lastSaved, setLastSaved] = useState<Date | null>(null);
  const [hasForcedOptionsBeenFlagged, setHasForcedOptionsBeenFlagged] =
    useState<boolean>(false);
  const templatesAllowed = useAccessControl("OptionTemplate", "GetTemplates");
  const RetrieveAllOptionsAccess = useAccessControl("Job", "GetAllOptions");
  const RetrieveOptionsCategoryAccess = useAccessControl(
    "Option",
    "GetOptionsCategory"
  );
  const FindCustomerByNameFromSFAccess = useAccessControl(
    "Customer",
    "FindCustomerByNameFromSF"
  );
  const GetQuoteAccess = useAccessControl("Quote", "RetrieveQuote");
  
  const UpdateQuoteAccess = useAccessControl("Quote", "UpdateQuote");

  const navigate = useNavigate();

  /*useEffect(() => {
    const abortController = new AbortController();
    setIsQuoteLoaded(false);
    dispatch({ type: "ResetQuote" });

    return () => {
      abortController.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);*/

  useEffect(() => {
    dispatch({
      type: "Quote",
      payload: {
        status: "clear"
      },
      source: "URL QuoteId change"
    });
  }, [quoteId])

  useEffect(() => {
    if (
      state.quote.status === "clear" ||
      state.quote.status === "blocked"
    ) {
      setHasForcedOptionsBeenFlagged(false);
    }
      
    if (state.quote.status === "clear") retrieveQuoteInfo();
    if (state.quote.status === "editing") {
      const listenersAreSet =
        window.onbeforeunload !== null ||
        window.onpopstate !== null ||
        window.onunload !== null;

      // If not, add event listeners
      if (listenersAreSet) {
        removeListeners();
      }
      window.addEventListener("beforeunload", handleBeforeUnload);
      window.addEventListener("popstate", handleBeforeUnload);
      window.addEventListener("unload", handleBeforeUnload);

      // Cleanup when component is unmounted:
      return () => {
        removeListeners();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.quote.status, GetQuoteAccess]);

  useEffect(() => {
    // Updates Buyer's info if it's different on SalesForce
    if (isQuoteLoaded) {
      if (
        FindCustomerByNameFromSFAccess &&
        state.quote.quoteBuyer?.salesforceAccountId &&
        state.quote.quoteBuyer?.salesforceAccountId !== "Spec"
      ) {
        findBuyerBYSFAccountIdProject(
          {
            salesforceAccountId: state.quote.quoteBuyer?.salesforceAccountId,
            projectId: state.quote.address?.projectID
          },
          (res: any) => {
            const matchingBuyers = res.data;
            console.log('SF AccountId:', res.data);
            if (matchingBuyers.length === 1 &&
              ( state.quote.quoteBuyer.salesforceOpportunityId !== res.data[0].id ||
                state.quote.quoteBuyer.salesforceConnectionNumber !== res.data[0].connectionNumber ||
                state.quote.quoteBuyer.salesforceStage !== res.data[0].stageName
              )
            ) {
              const quoteBuyer = { 
                ...state.quote.quoteBuyer,
                salesforceOpportunityId: res.data[0].id,
                salesforceConnectionNumber: res.data[0].connectionNumber,
                salesforceStage: res.data[0].stageName
              };
              const payload:UpdateQuoteType = {
                salesforceOpportunityId: res.data[0].id,
                salesforceConnectionId: res.data[0].connectionId,
                salesforceConnectionNumber: res.data[0].connectionNumber,
                salesforceStage: res.data[0].stageName
              };
              UpdateQuoteAccess &&
                updateQuote(
                  quoteId as string,
                  payload,
                  (res: any) => {
                    dispatch({
                      type: "Quote",
                      payload: {
                        quoteBuyer
                      },
                      source: "update SF Buyer info",
                    });
                    dispatch({
                      type: "Snackbar",
                      payload: {
                        show: true,
                        message:
                          "The SF info has been updated in Salesforce and is reflected in the Quote.",
                        severity: "success",
                      },
                    });
                  },
                  (err: any) => {
                    dispatch({
                      type: "Snackbar",
                      payload: {
                        show: true,
                        message: err.response.data.Message,
                        severity: "warning",
                      },
                    });
                  }
                );
            }
          },
          (err: any) => {
            const errorTypes = Object.getOwnPropertyNames(
              err.response.data.errors
            );
            errorTypes.forEach((errorType: any) => {
              err.response.data.errors[errorType].forEach((errMsg: string) => {
                dispatch(
                  dispatchError({
                    message: err.message,
                    statusText: err.response.statusText,
                    title: err.response.data.title,
                    status: err.response.status,
                    detail: errMsg,
                    data: err.response.data,
                  })
                );
              });
            });
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isQuoteLoaded,
    //ConverQuoteAccess,
    FindCustomerByNameFromSFAccess,
  ]);

  useEffect(() => {
    if (lastSaved === null && lastSaved !== state.quote.lastSaved) {
      retrieveQuoteInfo();
      setLastSaved(state.quote.lastSaved);
    }
    if (lastSaved !== null && lastSaved !== state.quote.lastSaved) {
      setLastSaved(state.quote.lastSaved);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.quote.lastSaved]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 70;
      if (window.scrollY > scrollThreshold && !isSummarySticky) {
        setIsSummarySticky(true);
      } else if (window.scrollY <= scrollThreshold && isSummarySticky) {
        setIsSummarySticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSummarySticky]);

  useEffect(() => {
    console.log("Quote", state.quote);
    /*if(state.quote.quoteBuyer)
      handleBuyerSearch(`${state.quote.quoteBuyer?.buyerFirstName} ${state.quote.quoteBuyer?.buyerLastName}`);*/
    RetrieveOptionsCategoryAccess && retrieveQuoteData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.quote.plan,
    state.quote.community,
    state.quote.elevation,
    state.quote.address?.projectID,
    RetrieveOptionsCategoryAccess,
  ]);

  useEffect(() => {
    setCoAmount(
      state.pendingItems
        .map((item: LineItems) => Number(item.quantity) * item.unitPrice)
        .reduce((acc: any, curr: any) => acc + curr, 0)
    );
    /*if(state.quote.hasSubmittedBasePlan &&
      state.pendingItems
        .some((item:OptionTypes) => item.category === "Plan and Elevation")
    ) {
      const newItems = state.pendingItems.filter(
        (item: OptionTypes) => item.category !== "Plan and Elevation"
      );
      dispatch({
        type: "QuotePending",
        payload: newItems,
        source: "Delete Plan & Elevation not allowed item",
      });
      dispatch({
        type: "Snackbar",
        payload: {
          duration: 5000,
          show: true,
          message: (
            <div
              style={{ alignItems: "center", display: "flex", flexWrap: "wrap" }}
            >
              {state.quote.address.address} ({state.quote.address.projectNumber}-{state.quote.address.jobNumber})
              already has a submitted base plan elevation.
              Use the action menu to change Plan or Elevation for an existing job.
              <Button
                onClick={() => {
                  window.open(
                    `./quote-summary?quotebuyerid=${state.quote.quoteBuyerId}`
                  );
                }}
                variant="outlined"
                sx={{
                  color: "white",
                  borderColor: "white",
                  marginLeft: "8px",
                }}
              >
                Open Job Summary
              </Button>
            </div>
          ),
          severity: "warning",
        },
      })
    }*/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.pendingItems //, state.quote.hasSubmittedBasePlan
  ]);

  useEffect(() => {
    if (!hasForcedOptionsBeenFlagged) {
      if (
        state.quote.status === "saved" &&
        state.availableOptions.length > 0
      ) {
        if (
          state.pendingItems.length > 0 &&
          state.availableOptions.length > 0
        ) {
          const newPendingItems = state.pendingItems.map(
            (item: OptionTypes) => {
              const resultItem = { ...item };
              const selectedOption = state.availableOptions.find(
                (option: OptionTypes) => option.description === item.description
              );
              if (selectedOption) {
                resultItem.forcedOption = selectedOption.forcedOption;
                resultItem.manualPrice = selectedOption.manualPrice;
              }
              return resultItem;
            }
          );
          dispatch({
            type: "QuotePending",
            payload: newPendingItems,
            preventStatusChange: true,
            source: "ForcedOptions validation",
          });
        }
        setHasForcedOptionsBeenFlagged(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.pendingItems, state.availableOptions]);

  // Prevent exiting without confirming:
  const handleBeforeUnload = (event: any) => {
    if (
      state.quote.lastSaved !== null ||
      state.quote.status === "editing"
    ) {
      event.preventDefault();
    }
  };

  const removeListeners = () => {
    window.removeEventListener("beforeunload", handleBeforeUnload);
    window.removeEventListener("popstate", handleBeforeUnload);
    window.removeEventListener("unload", handleBeforeUnload);
  };

  const retrieveQuoteInfo = () => {
    setIsQuoteLoaded(false);
    if (quoteId && GetQuoteAccess) {
      getQuote(
        quoteId,
        (res: any) => {
          console.log('quote raw data', res);
          dispatch({
            type: "Navbar",
            payload: { title: `Quote: ${res.data.quoteNumber}` },
          })
          setHasForcedOptionsBeenFlagged(false);
          /*if (res.data.submittedAt !== null) {
            // Redirect to read-only view if quote has been submitted already
            navigate(`/view?quoteId=${quoteId}`);
          }*/
          const payload = { ...state.quote };
          payload.id = quoteId;
          payload.keyword = res.data.quoteKeyword || "";
          payload.quoteStatus = res.data.quoteStatus;
          if(res.data.quoteStatus === "Converted") {
            payload.intentionId = res.data.convertedIntentionId;
          }
          payload.address ={
            address: res.data.address ?? "TBD / Preliminary",
            community: res.data.communityName,
            jobId: res.data.jobId,
            planElevationId: res.data.planElevationId,
            planElevationNumber: res.data.planNumberAndElevation.split("-")[1],
            planId: res.data.planId,
            planNumber: res.data.planNumberAndElevation.split("-")[0],
            projectID: res.data.projectId
          }
          /*payload.cmId = res.data.sites[0]?.cmId;
          payload.cmName = res.data.sites[0]?.cmName;*/
          /*payload.community = {
            name: res.data.sites[0]?.communityName,
            projectId: res.data.sites[0]?.projectID,
            projectNumber: res.data.sites[0]?.projectNumber,
          };
          if (res.data.submittedAt === null) {
            const currentQuote = res.data.quoteNumbers.find(
              (quote: any) => quote.isCurrent
            );
            payload.currentQuote = currentQuote;
          }*/
          payload.quoteBuyer = {
            email: res.data.buyer.email,
            firstName: res.data.buyer.firstName,
            id: res.data.buyer.id,
            lastName: res.data.buyer.lastName,
            phone: res.data.buyer.phone,
            quoteBuyerId: res.data.quoteBuyerId,
            salesforceAccountId: res.data.buyer.salesforceAccountId,
            salesforceConnectionNumber: res.data.salesforceConnectionNumber,
            salesforceOpportunityId: res.data.salesforceOpportunityId,
            salesforceStage: res.data.salesforceStage,
            source: res.data.buyer.source
          };
          payload.plan = {
            abbreviation: "",
            community: res.data.communityName,
            description: "",
            planElevationId: res.data.planElevationId,
            planElevationNumber: res.data.planNumberAndElevation.split("-")[1],
            planId: res.data.planId,
            planNumber: res.data.planNumberAndElevation.split("-")[0],
            projectID: res.data.projectId,
            projectNumber: ""
          };
          /*payload.keyword = res.data.keyword;
          payload.lot = res.data.sites[0]?.lot || "";
          payload.elevation = {
            planElevationID: res.data.sites[0]?.planElevationID,
            planElevationNumber:
              res.data.sites[0]?.planElevationNumber,
          };
          payload.planElevationOverride =
            res.data.sites[0]?.planElevationOverride;*/
          let currentSort: number;
          let currentHighlandCategory: string | null = null;
          const pendingItems = res.data.options
            ? res.data.options
                .sort((objA: any, objB: any) => {
                  // which highlandCategory this item belongs to?
                  const hcA = state.highlandCategories.find(
                    (hc: HighlandCategoryOption) =>
                      hc.key === objA.highlandCategory
                  );
                  const hcB = state.highlandCategories.find(
                    (hc: HighlandCategoryOption) =>
                      hc.key === objB.highlandCategory
                  );
                  if (hcA.sort !== hcB.sort) return hcA.sort - hcB.sort;
                  if (objA.sortOrder !== objB.sortOrder)
                    return objA.sortOrder - objB.sortOrder;
                  if (objA.category !== objB.category)
                    return objA.category.localeCompare(objB.category);
                  return objA.description.localeCompare(objB.description);
                })
                .map((item: any, itemIx: number) => {
                  const newItem = { ...item };
                  newItem.highlandCategory = state.highlandCategories.find(
                    (category: HighlandCategoryOption) =>
                      category.key === item.highlandCategory
                  );
                  newItem.forcedOption = false;
                  newItem.isNew = false;
                  newItem.isRequired = false;
                  newItem.manualPrice = item.manualPrice || false;
                  newItem.option = item.category;
                  currentSort =
                    itemIx === 0 ||
                    item.highlandCategory !== currentHighlandCategory
                      ? 1
                      : currentSort + 1;
                  newItem.sortOrder = currentSort;
                  currentHighlandCategory = item.highlandCategory;
                  return newItem;
                })
            : [];
          if (pendingItems.length > 0) {
            const lastSaved = new Date(res.data.createdAt);
            payload.lastSaved = lastSaved;
            setLastSaved(lastSaved);
          }
          //payload.stage = res.data.sites[0]?.stage;
          payload.status = "saved";

          // Building used HighlandCategories out of data:
          const pendingCategories: HighlandCategoryOption[] = [];
          if (res.data.options) {
            res.data.options.forEach((item: any) => {
              if (
                item.highlandCategory !== null &&
                !pendingCategories.some(
                  (category: HighlandCategoryOption) =>
                    category.key === item.highlandCategory
                )
              ) {
                pendingCategories.push(
                  state.highlandCategories.find(
                    (category: HighlandCategoryOption) =>
                      category.key === item.highlandCategory
                  )
                );
              }
            });
          }
          payload.pendingCategories = pendingCategories;
          dispatch({
            type: "Quote",
            payload,
            source: "Quote page load",
          });
          dispatch({
            type: "QuotePending",
            payload: pendingItems,
            preventStatusChange: true
          });
          dispatch({
            type: "PendingCategories",
            payload: pendingCategories
          });

          //
          /*if (res.data.submittedAt === null) {
            dispatch({
              type: "Navbar",
              payload: {
                title: "Pending Change Order : ",
              },
            });
          } else {
            dispatch({
              type: "Navbar",
              payload: { title: "Submitted Change Order" },
            });
          }*/
          setIsQuoteLoaded(true);
        },
        (err: any) => {
          console.log(err);
          dispatch(dispatchError(err.response.data));

          // Redirect back to home, since this one doesn't work:
          navigate("/");
        }
      );

      /*getQuoteUpdates(
        intentionId,
        (res:any) => {
          console.log(res);
          // display snackbar messages.
          if (res.data && res.data.length > 0) {
            let updateIx = 0;
            const displayUpdate = () => {
              if (updateIx < res.data.length) {
                dispatch({
                  type: "Snackbar",
                  payload: {
                    show: true,
                    message: res.data[updateIx].message,
                    severity: "success",
                  },
                });
                updateIx++;
                setTimeout(displayUpdate, 3000);
              }
            };
            displayUpdate();
          }
        },
        (err: any) => {
          dispatch(
            dispatchError({
              message: err.message,
              statusText: err.response.statusText,
              title: err.response.data.title,
              status: err.response.status,
              detail: err.response.data.detail,
              data: err.response.data,
            })
          );
        }
      );*/
    }
  };

  const retrieveQuoteData = () => {
    console.log("Quote", state.quote);
    if (
      state.quote.plan?.planId &&
      state.quote.plan?.projectID && 
      state.quote.plan?.planElevationId
    ) {
      retrieveOptionCategories(
        {
          PlanId: state.quote.plan.planId,
          ProjectId: state.quote.address.projectID,
          PlanElevationId: state.quote.address.planElevationId,
        },
        (res: any) => {
          // build the category catalog:
          const newOptionsCategory = res.data.map((obj: OptionsCategory) => ({
            ...obj,
          }));
          state.highlandCategories.forEach(
            (highlandCategory: HighlandCategoryOption) => {
              newOptionsCategory.push(
                {
                  option: "Manual Option",
                  highlandCategory: highlandCategory.key,
                  category: "Manual Option",
                },
                {
                  option: "Formatting Separator",
                  highlandCategory: highlandCategory.key,
                  category: "Formatting Separator",
                }
              );
            }
          );
          dispatch({
            type: "AvailableOptionCategories",
            payload: newOptionsCategory,
          });
        },
        (err: any) =>
          dispatch(
            dispatchError({
              message: err.message,
              statusText: err.response.statusText,
              title: err.response.data.title,
              status: err.response.status,
              detail: err.response.data.detail,
              data: err.response.data,
            })
          )
      );

      RetrieveAllOptionsAccess &&
        retrieveAllOptions(
          {
            PlanId: state.quote.plan.planId,
            ProjectId: state.quote.address.projectID,
            PlanElevationId: state.quote.address.planElevationId,
          },
          (res: any) => {
            const newOptions = res.data;
            newOptions.sort((a: any, b: any) => {
              const itemDescA =
                a.description.split(":").length < 2
                  ? a.description.trim()
                  : a.description.split(":")[1].trim();
              const itemDescB =
                b.description.split(":").length < 2
                  ? b.description.trim()
                  : b.description.split(":")[1].trim();
              if (itemDescA < itemDescB) return -1;
              else if (itemDescA > itemDescB) return 1;
              return 0;
            });
            state.highlandCategories.forEach(
              (highlandCategory: HighlandCategoryOption) => {
                newOptions.push(
                  {
                    option: "Manual Option",
                    description: "",
                    highlandCategory: highlandCategory.key,
                    category: "Manual Option", //highlandCategory.key.toUpperCase(),
                    optionAbbreviation: "Manual Option",
                    optionCategoryID: 0,
                    optionCode: "",
                    optionId: 0,
                    salesPrice: "",
                    salesPriceControlID: 0,
                  },
                  {
                    option: "Formatting Separator",
                    description: "",
                    highlandCategory: highlandCategory.key,
                    category: "Formatting Separator",
                    optionAbbreviation: "Formatting Separator",
                    optionCategoryId: 0,
                    optionCode: "",
                    optionId: 0,
                    salesPrice: "",
                    salesPriceControlID: 0,
                  }
                );
              }
            );
            dispatch({
              type: "AvailableOptions",
              payload: newOptions,
            });
          },
          (err: any) =>
            dispatch(
              dispatchError({
                message: err.message,
                statusText: err.response.statusText,
                title: err.response.data.title,
                status: err.response.status,
                detail: err.response.data.detail,
                data: err.response.data,
              })
            )
        );

      if (templatesAllowed) {
        getTemplates(
          {
            projectId: state.quote.address.projectID,
            planId: state.quote.address.planId,
          },
          (res: any) => {
            const templatesData = res.data.optionTemplates
              ? res.data.optionTemplates.map((template: any) => {
                  return {
                    id: template.id,
                    name: template.templateName,
                    createdAt: new Date(template.createdAt),
                    createdBy: template.createdByName,
                  };
                })
              : [];
            dispatch({
              type: "AvailableTemplates",
              payload: templatesData,
            });
          },
          (res: any) =>
            dispatch({
              type: "Snackbar",
              payload: {
                show: true,
                message: `${res.message} - ${res.response.statusText} -${res.response.data}`,
                severity: "error",
              },
            })
        );
      }
    }
  };

  return (
    <ThemeProvider theme={mainTheme}>
      <Container
        sx={{
          my: mainTheme.spacing(2),
        }}
        component="main"
        maxWidth={false}
      >
        <CssBaseline />
        <Stack
          className={`summaries-wrapper${isSummarySticky ? " sticky" : ""}`}
          sx={{
            width: "100%",
            height: "100%",
          }}
          gap={2}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <QuoteHeader />
          <Stack
            alignItems={"center"}
            alignContent={"center"}
            gap={2}
            flexDirection={"row"}
            sx={{
              width: "100%",
              alignItems: "center",
              border: 1,
              borderColor: (mainTheme) => mainTheme.palette.grey[300],
              borderTop: 4,
              borderRadius: "10px",
              borderTopColor: (mainTheme) => mainTheme.palette.primary.main,
              justifyContent: { xs: "center", md: "space-around" },
              flexDirection: { xs: "column", md: "row" },
              maxWidth: { xs: "100%", lg: state.quote.quoteStatus === "Converted" ? "15%" : "10%" },
              py: 0.5,
              px: 2,
            }}
          >
            { state.quote.quoteStatus === "Converted" && 
              <>
                <div>
                    <p style={{ margin: "0 auto .5rem" }}>
                    <strong>Converted</strong>
                  </p>
                  <p style={{ margin: "0 auto", textAlign: "center" }}>
                    <CheckCircle sx={{ color: "#2e7d32", fontSize: "1.5rem", lineHeight: "1" }} />
                  </p>
                </div>
                <div style={{ backgroundColor: "#E0E0E0", width: "1px", height: "56px"}}></div>
              </>
            }
            <div>
              <p style={{ margin: "0 auto .5rem" }}>
                <strong>Quote Amount</strong>
              </p>
              <p style={{ margin: "0 auto", textAlign: "right" }}>
                {isQuoteLoaded ? (
                  `$ ${numberWithCommas(coAmount)}`
                ) : (
                  <Skeleton variant="text" height={24} width={100} />
                )}
              </p>
            </div>
          </Stack>
        </Stack>
        {isQuoteLoaded &&
        state.availableOptions.length > 0 &&
        state.availableOptionCategories.length > 0 ? (
          <div className={`pending-main${isSummarySticky ? " sticky" : ""}`}>
            <OptionCategories mode="quote" />
          </div>
        ) : (
          <>
            <Skeleton
              variant="rectangular"
              animation="wave"
              height={40}
              sx={{ marginBottom: "4px", marginTop: "40px" }}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              height={46}
              sx={{ marginBottom: "4px" }}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              height={46}
              sx={{ marginBottom: "4px" }}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              height={46}
              sx={{ marginBottom: "4px" }}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              height={55}
              sx={{ marginBottom: "4px" }}
            />
          </>
        )}
        <QuoteFooter />
        { state.clDialogs.length > 0 && 
          <Dialog open={true}>
            <DialogContent>
              <Stack direction="row">
                { state.clDialogs[0].img && <img src={state.clDialogs[0].img} style={{ height: "140px", width: "auto"}} /> }
                <div style={{ paddingLeft: "10px"}}>
                  {state.clDialogs[0].body}
                </div>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  const newClDialogs = [...state.clDialogs];
                  newClDialogs.shift();
                  dispatch({
                    type: "ClDialogs",
                    payload: newClDialogs
                  });
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        }
      </Container>
    </ThemeProvider>
  );
}
