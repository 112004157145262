import { useEffect, useState, useContext } from "react";
import { Autocomplete, Box, Button, DialogActions, DialogContent, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { dispatchError } from "../../common/fx";
import { filterOptions } from "../../utils/filterOptions";
import ReusableDialog from "../../components/Dialog";
import { UIContext, UIState, AddressTypes, OptionTypes } from "../../providers/UIProvider";
import { convertQuoteToCO, getAvailableAddresses, updateQuote } from "../../apiCalls";

function AssignJobToQuote() {
  const [open, setOpen] = useState(false);
  const [addresses, setAddresses] = useState<AddressTypes[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<AddressTypes | null>(null);
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [isConvertToCODisabled, setIsConvertToCODisabled] = useState<boolean>(true);
  const [disabledToolTip, setDisabledToolTip] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    if(state.quote.address) {
      getAvailableAddresses(
        {
          projectId: state.quote.address.projectID,
          planId: state.quote.address.planId,
          planElevationId: state.quote.address.planElevationId
        },
        (response:any) => {
          setAddresses(response);
        },
        (err:any) => {
          dispatch(
            dispatchError({
              message: err.message,
              statusText: err.response.statusText,
              title: err.response.data.title,
              status: err.response.status,
              detail: err.response.data.detail,
              data: err.response.data,
            })
          );
        }
      );
    }
  }, [state.quote.address]);

  useEffect(() => {
    const result = 
      state.quote.lastSaved === null ||
      !state.quote.quoteBuyer?.salesforceAccountId ||
      !state.quote.quoteBuyer?.salesforceOpportunityId ||
      state.quote.status === "editing" ||
      state.pendingItems.length === 0 ||
      state.pendingItems.filter((item: OptionTypes) => item.isNew)
        .length > 0 ||
      addresses.length === 0;
    setIsConvertToCODisabled(result);
    if(result) {
      let newToolTip = "";
      if(!state.quote.quoteBuyer || !state.quote.quoteBuyer.salesforceAccountId) {
        newToolTip = "Verify Buyer";
      }
      if(!state.quote.quoteBuyer || !state.quote.quoteBuyer.salesforceOpportunityId) {
        if(newToolTip !== "") newToolTip += "\r";
        newToolTip += "Verify Salesforce Connection";
      }
      if(addresses.length === 0) {
        if(newToolTip !== "") newToolTip += "\r";
        newToolTip += "There are no empty lot job/address for this Community";
      }
      setDisabledToolTip(newToolTip);
    } else {
      setDisabledToolTip("");
    }
  }, [state.quote.lastSaved, state.quote.quoteBuyer, state.quote.address, state.quote.status, state.pendingItems]);

  const handleClose = () => {
    setOpen(false);
  }

  const handleAssignAndConvert = () => {
    updateQuote(
      state.quote.id,
      { jobId: selectedAddress?.jobID },
      (response:any) => {
        console.log(response);
        //convert to CO:
        convertQuoteToCO(
          state.quote.id,
          (res:any) => {
            console.log('convertQuote', res);
            dispatch({
              type: "Snackbar",
              payload: {
                show: true,
                message: `The Quote ${
                  state.quote.address && state.quote.address.address} has been converted successfully!`,
                severity: "success",
              },
            });
            dispatch({ type: "ResetChangeOrder" });
            navigate(`/edit?intentionId=${res.data}`);
          },
          (err:any) => {
            const errorMessage = err.response.data.errors.OperationCanceledException[0] ?? err.message;
            dispatch(
              dispatchError({
                message: err.message,
                statusText: err.response.statusText,
                title: err.response.data.title,
                status: err.response.status,
                detail: errorMessage,
                data: err.response.data,
              })
            );
          }
        );
      },
      (err:any) => {
        dispatch(
          dispatchError({
            message: err.message,
            statusText: err.response.statusText,
            title: err.response.data.title,
            status: err.response.status,
            detail: err.response.data.detail,
            data: err.response.data,
          })
        );
      }
    )
  }

  return (
    <ReusableDialog
      buttonText="Convert to CO"
      content={
        <>
          <DialogContent>
            <Box marginY={(theme) => theme.spacing(2)}>
                <Autocomplete
                  multiple={false}
                  filterOptions={filterOptions}
                  fullWidth
                  getOptionLabel={(option:any) => {
                    return(`${option.address} (${
                      option.planNumber ?? state.quote.address.planNumber}-${
                      option.planElevationNumber ?? state.quote.address.planElevationNumber}) | ${option.projectNumber}-${option.jobNumber}`);
                  }}
                  size="medium"
                  onChange={(e, value, reason) => {
                    if(reason === "clear") {
                      setSelectedAddress(null);
                    } else {
                      setSelectedAddress(value);
                    }
                  }}
                  options={addresses as AddressTypes[]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Address"
                      fullWidth
                    />
                  )}
                  value={selectedAddress}
                />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
              }}
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleAssignAndConvert();
              }}
              variant="contained"
              color="primary"
            >
              Assign & Convert
            </Button>
          </DialogActions>
        </>
      }
      disabled={isConvertToCODisabled}
      isOpen={open}
      setIsOpen={(value) => setOpen(value)}
      title="Assign Address to Convert Quote"
      toolTipTitle={disabledToolTip}
    />
  )
}

export default AssignJobToQuote;