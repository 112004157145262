import { useContext, useState, useEffect } from "react";
import { LinearProgress, Typography } from "@mui/material";
import {
  GridRowModesModel,
  GridRowModes,
  DataGridPro,
  GridColDef,
  useGridApiRef,
  GridRenderEditCellParams,
  GridRowModel,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";

import {
  HighlandCategoryOption,
  OptionTypes,
  UIContext,
  UIState,
} from "../../providers/UIProvider";
import DropdownOptionsCell from "./DropdownOptionsCell";
import TextInputCell from "./TextInputCell";
import GridCustomFooter from "../../components/GridCustomFooter";
import { numberWithCommas } from "../../utils/formatMoney";
import Loading from "../../components/Loading";
import { isNull } from "lodash";

interface Props {
  category: HighlandCategoryOption;
}

export default function EditGrid(props: Props) {
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [gridColumns, setGridColumns] = useState<any[]>([]);
  const [gridRows, setGridRows] = useState<OptionTypes[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [firstId, setFirstId] = useState<String | null>(null);
  const apiRefGrid = useGridApiRef();

  useEffect(() => {
    const newRows = buildRows();
    setGridRows(newRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.pendingItems]);

  useEffect(() => {
    setGridColumns(buildColumns());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridRows]);

  useEffect(() => {
    if (firstId === null) {
      const newFirstId = state.pendingItems.find(
        (item: OptionTypes) => item.highlandCategory.key === props.category.key
      );
      if (newFirstId) {
        if (newFirstId.id && !newFirstId.optionCode) {
          setRowModesModel({
            ...rowModesModel,
            [newFirstId.id]: { mode: GridRowModes.Edit },
          });
        }
        setFirstId(newFirstId.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridColumns]);

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    return oldRow; // for some weird reason, this hack works like this. Don't f*** mess with this.
  };

  const renderTextInputCell: GridColDef["renderCell"] = (params: any) => {
    return <TextInputCell {...params} />;
  };

  const renderDropdownInputCell: GridColDef["renderCell"] = (params: any) => {
    return <DropdownOptionsCell {...params} />;
  };

  const buildRows = () => {
    return state.pendingItems
      .filter((item: OptionTypes) => {
        return item.highlandCategory.key === props.category.key;
      })
      .map((obj: any) => ({
        ...obj,
        __reorder__: obj.sortOrder,
      }));
  };

  const buildColumns = () => {
    const categoryOptions = [
      ...state.availableOptionCategories
        .filter(
          (optionCategory: any) =>
            optionCategory.highlandCategory === props.category.key
        )
        .sort((a: { category: string }, b: { category: string }) => {
          if (a.category > b.category) {
            return 1;
          }
          if (a.category < b.category) {
            return -1;
          }
          return 0;
        }),
    ];
    return [
      {
        field: "option",
        headerName: "Option",
        width: 300,
        editable: false,
        type: "string",
        valueOptions: categoryOptions,
        renderEditCell: (params: GridRenderEditCellParams) =>
          renderDropdownInputCell({
            ...params,
            apiRefGrid,
            setRows: dispatch,
            setRowModesModel: setRowModesModel,
            Rows: state.pendingItems,
            RowModel: rowModesModel,
          } as any),
      },
      {
        field: "optionCode",
        headerName: "Option Code",
        width: 200,
        editable: false,
        valueOptions: state.availableOptions
          .filter(
            (optionCategory: any) =>
              optionCategory.highlandCategory === props.category.key
          )
          .sort((a: { category: string }, b: { category: string }) => {
            if (a.category > b.category) {
              return 1;
            }
            if (a.category < b.category) {
              return -1;
            }
            return 0;
          }),
        renderEditCell: (params: GridRenderEditCellParams) => {
          return params.row.option !== "Custom Option"
            ? renderDropdownInputCell({
                ...params,
                apiRefGrid,
                setRows: dispatch,
                setRowModesModel: setRowModesModel,
                Rows: state.pendingItems,
                RowModel: rowModesModel,
              } as any)
            : renderTextInputCell({
                ...params,
                type: "text",
                maxLength: 11,
                placeholder: "Enter your custom code",
                setRows: dispatch,
                setRowModesModel: setRowModesModel,
                Rows: state.pendingItems,
                RowModel: rowModesModel,
              } as any);
        },
      },
      {
        field: "description",
        headerName: "Description",
        width: 400,
        valueOptions: state.availableOptions
          .filter(
            (optionCategory: any) =>
              optionCategory.highlandCategory === props.category.key
          )
          .sort((a: { category: string }, b: { category: string }) => {
            if (a.category > b.category) {
              return 1;
            }
            if (a.category < b.category) {
              return -1;
            }
            return 0;
          }),
        editable: false,
        type: "string",
        renderEditCell: (params: GridRenderEditCellParams) => {
          return params.row.option !== "Custom Option"
            ? renderDropdownInputCell({
                ...params,
                apiRefGrid,
                setRows: dispatch,
                setRowModesModel: setRowModesModel,
                Rows: state.pendingItems,
                RowModel: rowModesModel,
              } as any)
            : renderTextInputCell({
                ...params,
                type: "text",
                placeholder: "Enter your custom description",
                setRows: dispatch,
                setRowModesModel: setRowModesModel,
                Rows: state.pendingItems,
                RowModel: rowModesModel,
              } as any);
        },
      },
      {
        field: "notes",
        headerName: "Notes",
        width: 400,

        renderCell: (params: any) => params.notes,
      },
      {
        field: "quantity",
        headerAlign: "right",
        headerName: "QTY",
        align: "right",
        editable: false,
        width: 130,
        renderCell: (params: any) =>
          params.row.category === "Formatting Separator" ? "" : params.value,
        renderEditCell: (params: GridRenderEditCellParams) =>
          renderTextInputCell({
            ...params,
            align: "right",
            max: 999,
            min: 1,
            type: "number",
            setRows: dispatch,
            setRowModesModel: setRowModesModel,
            Rows: state.pendingItems,
            RowModel: rowModesModel,
          } as any),
      },
      {
        field: "unitPrice",
        headerAlign: "right",
        headerName: "Unit Price",
        align: "right",
        editable: false,
        valueGetter: (params: any) => params.row.unitPrice,
        valueFormatter: (params: GridValueFormatterParams) =>
          `$ ${numberWithCommas(params.value)}`,
        width: 180,
        renderCell: (params: any) =>
          params.row.category === "Formatting Separator" ? "" : params.value,
        renderEditCell: (params: GridRenderEditCellParams) =>
          params.row.option !== "Custom Option" ? (
            <Typography align="right">{params.formattedValue}</Typography>
          ) : (
            renderTextInputCell({
              ...params,
              align: "right",
              type: "number",
              disabled: params.row.option !== "Custom Option",
              min: -10000000,
              max: 10000000,
              placeholder: "0",
              setRows: dispatch,
              setRowModesModel: setRowModesModel,
              Rows: state.changeOrder.pendingItems,
              RowModel: rowModesModel,
            } as any)
          ),
      },
      {
        field: "totalPrice",
        headerAlign: "right",
        headerName: "Total Price",
        align: "right",
        valueFormatter: (params: GridValueFormatterParams) =>
          `$ ${numberWithCommas(params.value)}`,
        width: 180,
        valueGetter: (params: any) => {
          return `${(
            params.row.quantity * Number(params.row.unitPrice)
          ).toFixed(2)}`;
        },
      },
    ];
  };

  function Footer() {
    return <GridCustomFooter highlandCategory={props.category} />;
  }

  return gridColumns && gridRows ? (
    <DataGridPro
      apiRef={apiRefGrid}
      columnHeaderHeight={46}
      columns={gridColumns}
      disableRowSelectionOnClick
      initialState={{
        pagination: { paginationModel: { pageSize: 100 } },
      }}
      getRowClassName={(params: any) =>
        `${!isNull(params?.row?.canceledBy) ? "canceled-row" : ""}`
      }
      onProcessRowUpdateError={(res) => console.log(res)}
      pagination
      processRowUpdate={processRowUpdate}
      rowCount={gridRows.length}
      rowHeight={46}
      rowModesModel={rowModesModel}
      rows={gridRows}
      slotProps={{
        toolbar: { dispatch, setRowModesModel },
      }}
      slots={{
        loadingOverlay: LinearProgress,
        footer: Footer,
      }}
      sx={{
        "& .css-1iledgx-MuiFormControl-root": {
          marginBottom: "0px",
        },
        "& .canceled-row": {
          textDecoration: "line-through",
        },
      }}
    />
  ) : (
    <Loading />
  );
}
