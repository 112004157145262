import React, { useReducer, createContext, ReactNode } from "react";
import {
  AddHomeWork,
  Architecture,
  Description,
  Engineering,
  Straighten,
  Tune,
} from "@mui/icons-material";

export interface AddressTypes {
  abbreviation: string;
  address: string;
  addressNumber: string;
  addressStreet: string;
  communityName: string;
  description: string | null;
  jobID: number;
  jobHasSubmittedCOs?: boolean;
  jobNumber: string;
  planElevationId: string | null;
  planElevationNumber: string | null;
  planId: string | null;
  planNumber: string | null;
  projectID: number;
  projectNumber: string;
}

export interface BuildTypes {
  id: string;
  createdBy: string;
  createdByName: string;
  createdAt: string;
  submittedBy: string;
  submittedByName: string;
  submittedAt: string;
  stateCode: string;
  stateDescription: string;
  summaryName: string;
  address: string;
  community: string;
  buyerName: string;
  totalSubmittedCOValue: number;
  totalChangeOrders: number;
  submittedChangeOrders: number;
  pendingChangeOrders: number;
}

export interface BuyerTypes {
  id: string;
  salesforceAccountId: string;
  contract_Buyer_Names_Combined__c: string;
  contractBuyerNames: string;
  connectionNumber: string;
}

export interface ChangeOrder {
  address: AddressTypes | null;
  addressFreeform: string;
  addressJobID: number | null;
  addressToBeDetermined: boolean;
  addressType: "knownAddress" | "lotBlock" | "freeForm";
  block: string;
  buyerType: "buyer" | "spec";
  cmName: string | null;
  cmId: number | null;
  community: CommunityTypes | null;
  currentIntention: IntentionType | null;
  customerBuyer: CustomerBuyerTypes | null;
  elevation: string;
  hasSubmittedBasePlan: boolean | null;
  isSpec: boolean;
  isPreliminary: boolean;
  keyword: string;
  lastSaved: Date | null;
  lot: string;
  notes: string;
  overrideSubdivision: string;
  planElevationOverride: string;
  plan: PlanTypes | null;
  planOverride: string;
  previousContractPrice: number;
  submittedCategories: HighlandCategoryOption[] | [];
  submittedItems: OptionTypes[] | [];
  stage: number | null;
  status: "clear" | "editing" | "saved" | "blocked";
  type: string;
}

export interface ChangeOrderAddressTypes {
  id: string;
  address: string;
  buyerName: string;
  changeOrder: number;
  baseSalesPrice: number;
  totalSubmittedCoValue: number;
  community: string;
  currentSalesPrice: number;
  totalCo: number;
  pendingCo: number;
}

export interface ChangeOrderSelectedAddressTypes {
  id: string;
  date: Date;
  submitted: Date;
  notes: string;
  beforeChandgeOrderPrice: number;
  changeOrderNumber: number;
  changeOrderPrice: number;
  changeOrderType: string;
  changeOrderNewPrice: number;
  status: string;
  buyerName: string;
}

export interface ClDialog {
  img: string | null;
  body: ReactNode;
}

export interface ConstructionManagerTypes {
  cmId: number;
  cmName: string;
}

export interface CommunityTypes {
  projectId: string;
  projectNumber: string;
  name: string;
  specialOption?: boolean;
}

export interface CustomerBuyerTypes {
  buyerType?: string;
  buyerFirstName?: string;
  buyerLastName?: string;
  connectionNumber: string;
  contractBuyerNames?: string | null;
  contract_Buyer_Names_Combined__c: string;
  id: string;
  salesforceAccountId?: string; //SalesForce
}

export interface ElevationTypes {
  abbreviation: string;
  community: string;
  description: string;
  planElevationID: string;
  planElevationNumber: string;
  planId: string;
  planNumber: string;
  projectID: string;
  projectNumber: string;
}

export interface HighlandCategoryOption {
  key: string;
  label: string;
  icon: React.ReactElement;
  sort: number;
}

export interface IntentionType {
  createdAt: string;
  createdBy: string;
  createdByName: string;
  isCurrent: boolean;
  number: string;
}

export interface LineItems {
  salesPriceControlID: number;
  optionID: number;
  projectID: number;
  segmentID: number;
  planID: number;
  planElevationID: number;
  communityName: string;
  projectNumber: string;
  planNumber: string;
  planElevationNumber: string;
  optionAbbreviation: string;
  optionCode: string;
  description: string;
  optionCategoryID: number;
  category: string;
  highlandCategory: string;
  salesPrice: number;
  id: string;
  isNew: boolean;
  notes: string;
  option: string;
  quantity: string;
  unitPrice: number;
}

export interface IntentionSummary {
  id: string;
  createdBy: string;
  createdByName: string;
  createdAt: string;
  submittedBy: string;
  submittedByName: string;
  submittedAt: string;
  stateCode: string;
  stateDescription: string;
  summaryName: string;
  address: string;
  community: string;
  buyerName: string;
  totalSubmittedCOValue: number;
  totalChangeOrders: number;
  submittedChangeOrders: number;
  pendingChangeOrders: number;
  intentions: [
    {
      id: string;
      createdBy: string;
      createdByName: string;
      createdAt: string;
      submittedBy: string;
      submittedByName: string;
      submittedAt: string;
      stateCode: string;
      stateDescription: string;
      changeOrderNumber: string;
      changeOrderType: string;
      status: string;
      buyerName: string;
      notes: string;
      previousContractPrice: number;
      changeOrderPrice: number;
      newContractPrice: number;
    }
  ];
}
export interface QuoteSummary {
  id: string;
  sfAccountId: string;
  buyerName: string;
  buyerPhone: string;
  buyerEmail: string;
  quotes: {
    address: string;
    communityName: string;
    keyword?: string;
    notes: string[];
    planNumberAndElevation: string;
    quoteId: string;
    quoteNumber: string;
    quotedPrice: number;
    quoteStatus: string;
    salesforceStage: string;
  }[];
}

export interface JobTypes {
  id: string;
  createdBy: string;
  createdByName: string;
  createdAt: string;
  submittedBy: string;
  submittedByName: string;
  submittedAt: string;
  stateCode: string;
  stateDescription: string;
  summaryName: string;
  address: string;
  community: string;
  buyerName: string;
  totalSubmittedCOValue: number;
  totalChangeOrders: number;
  submittedChangeOrders: number;
  pendingChangeOrders: number;
}

export interface MeasuredOptionsType {
  materialType: string;
  optionLevel: string;
  optionLocation: string;
  salesPrice: number;
  salesPriceControlId: number;
}

export interface OptionsCategory {
  optionID: number;
  category: string;
}

export interface OptionTypes {
  category: string;
  communityName: string;
  cancelsOptionId?: string;
  description: string;
  forcedOption: boolean;
  highlandCategory: HighlandCategoryOption;
  id: string;
  isNew: boolean;
  isRequired: boolean;
  manualPrice?: boolean;
  option: string;
  optionAbbreviation: string;
  optionCode: string;
  optionCategoryID: number;
  optionID: number;
  planElevationID: number;
  planElevationNumber: string;
  planID: number;
  planNumber: string;
  projectID: number;
  projectNumber: string;
  salesPriceControlID: number;
  segmentID: number;
  salesPrice: number;
  sortOrder: number;
}

export interface PlanTypes {
  projectID: string;
  projectNumber: string;
  community: string;
  planId: string;
  planNumber: string;
  description: string;
  abbreviation: string;
  planElevationID: string;
  planElevationNumber: string;
}

export interface Quote {
  address: AddressTypes | null;
  cmName: string | null;
  cmId: number | null;
  id: string;
  intentionId?: string;
  keyword: string;
  lastSaved: Date | null;
  pendingItems: OptionTypes[] | [];
  plan: PlanTypes | null;
  quoteBuyer: QuoteBuyerTypes | null;
  quoteStatus: "Open" | "Converted";
  status: "clear" | "editing" | "saved" | "blocked";

  /*addressFreeform: string;
  addressJobID: number | null;
  addressToBeDetermined: boolean;
  addressType: "knownAddress" | "lotBlock" | "freeForm";
  block: string;
  buyerType: "buyer" | "spec";
  community: CommunityTypes | null;
  currentIntention: IntentionType | null;
  customerBuyer: CustomerBuyerTypes | null;
  elevation: string;
  hasSubmittedBasePlan: boolean | null;
  isSpec: boolean;
  isPreliminary: boolean;
  keyword: string;
  lot: string;
  notes: string;
  overrideSubdivision: string;
  pendingCategories: HighlandCategoryOption[] | [];
  planElevationOverride: string;
  planOverride: string;
  previousContractPrice: number;
  submittedCategories: HighlandCategoryOption[] | [];
  submittedItems: OptionTypes[] | [];
  stage: number | null;
  type: string;*/
}

export interface QuoteBuyerTypes {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  phone: string;
  quoteBuyerId: string;
  salesforceAccountId: string;
  salesforceConnectionNumber: string;
  salesforceOpportunityId: string;
  salesforceStage: string;
  source: string;
}

export type UIState = {
  isAdmin: boolean;
  navbar: {
    title: string;
    reactElement?: React.ReactNode;
  };
  COSummaryItems: any;
  communities: CommunityTypes[] | [];
  availableJobs:
    | {
        sold: JobTypes[];
        notSold: JobTypes[];
        pending: JobTypes[];
      }
    | {};
  availableAddresses: AddressTypes[] | [];
  availableOptions: OptionTypes | [];
  availableOptionCategories: OptionsCategory | [];
  availablePlans: PlanTypes[] | [];
  availableElevations: ElevationTypes[] | [];
  availableBuyerNames: CustomerBuyerTypes[] | [];
  availableBuyerQuoteNames: CustomerBuyerTypes[] | [];
  snackbar: {
    show: boolean;
    message: string | React.ReactNode;
    duration?: number;
    action?: React.ReactNode;
    severity: "error" | "warning" | "info" | "success";
    vertical: "top";
    horizontal: "center";
  };
  clDialogs: ClDialog[];
  constructionManagers: ConstructionManagerTypes[] | [];
  createChangeOrderForm: {
    community: string;
    isSpec: boolean;
    addressFreeform: string;
    planOverride: string;
    planElevationOverride: string;
    addressToBeDetermined: boolean;
    isPreliminary: boolean;
    customerBuyer: CustomerBuyerTypes | null;
    address: string;
    addressType: "knownAddress" | "lotBlock" | "freeForm";
    lot: string;
    block: string;
    buyerType: "buyer" | "spec";
    plan: string;
    overrideSubdivision: string;
    elevation: string;
    type: string;
    notes: string;
  };
  createQuoteForm: {
    community: string;
    isSpec: boolean;
    addressFreeform: string;
    planOverride: string;
    planElevationOverride: string;
    addressToBeDetermined: boolean;
    isPreliminary: boolean;
    customerBuyer: CustomerBuyerTypes | null;
    address: string;
    addressType: "knownAddress" | "lotBlock" | "freeForm";
    lot: string;
    block: string;
    buyerType: "buyer" | "spec";
    plan: string;
    customerBuyerFreeForm: string;
    overrideSubdivision: string;
    elevation: string;
    type: string;
    notes: string;
  };
  currentTemplate: TemplateType | null;
  templates: TemplateType[];
  changeOrder: ChangeOrder;
  highlandCategories: HighlandCategoryOption[];
  pendingCategories: HighlandCategoryOption[];
  pendingItems: OptionTypes[];
  quote: Quote;
  selectedJob: IntentionSummary | {};
  selectedBuyerQuote: QuoteSummary | {};
};

export type TemplateType = {
  id: string;
  name: string;
  createdBy: string;
  createdAt: Date;
};

export type RoleController = {
  controllerName: string;
  actions: RolePermission[];
  isExpanded?: boolean;
};

export type RolePermission = {
  action: string;
  description: string;
  active: boolean;
};

export type RoleType = {
  id: string;
  name: string;
  description: string;
  scope: string;
  teamName: string;
  access: RoleController[];
};

const initialChangeOrder: ChangeOrder = {
  address: null,
  addressJobID: null,
  addressFreeform: "TBD / Preliminary",
  addressToBeDetermined: false,
  addressType: "knownAddress",
  block: "",
  buyerType: "buyer",
  cmId: null,
  cmName: null,
  community: null,
  currentIntention: null,
  customerBuyer: null,
  elevation: "",
  hasSubmittedBasePlan: null,
  isPreliminary: false,
  isSpec: false,
  keyword: "",
  lastSaved: null,
  lot: "",
  notes: "",
  overrideSubdivision: "",
  plan: null,
  planElevationOverride: "",
  planOverride: "",
  previousContractPrice: 0,
  stage: null,
  submittedCategories: [],
  submittedItems: [],
  type: "",
  status: "clear",
};

const initialQuote: Quote = {
  address: null,
  cmName: null,
  cmId: null,
  id: "",
  keyword: "",
  lastSaved: null,
  pendingItems: [],
  plan: null,
  quoteBuyer: null,
  quoteStatus: "Open",
  status: "clear",
};

const initialState: UIState = {
  changeOrder: { ...initialChangeOrder },
  selectedJob: {
    intentions: [],
  },
  selectedBuyerQuote: {},
  COSummaryItems: {
    items: [],
  },
  availableJobs: {
    sold: [],
    notSold: [],
    pending: [],
  },
  isAdmin: true,
  availableOptions: [],
  availableOptionCategories: [],
  availableBuyerNames: [],
  availableBuyerQuoteNames: [],
  availableAddresses: [],
  availablePlans: [],
  availableElevations: [],
  clDialogs: [],
  communities: [],
  constructionManagers: [],
  currentTemplate: null,
  highlandCategories: [
    {
      key: "BasePlan",
      label: "Plan & Elevation",
      icon: <AddHomeWork />,
      sort: 1,
    },
    {
      key: "Structural",
      label: "Structural",
      icon: <Architecture />,
      sort: 2,
    },
    { key: "AAA", label: "Options", icon: <Engineering />, sort: 3 },
    {
      key: "MeasuredOption",
      label: "Measured Options",
      icon: <Straighten />,
      sort: 4,
    },
    { key: "custom", label: "Manual", icon: <Tune />, sort: 5 },
    {
      key: "StandardLanguage",
      label: "Standard Language",
      icon: <Description />,
      sort: 6,
    },
  ],
  navbar: {
    title: "Change Order",
    reactElement: null,
  },
  snackbar: {
    show: false,
    message: "An Error Happened",
    severity: "error",
    vertical: "top",
    horizontal: "center",
  },
  createChangeOrderForm: {
    community: "",
    planOverride: "",
    planElevationOverride: "",
    isSpec: false,
    addressToBeDetermined: false,
    isPreliminary: false,
    addressType: "knownAddress",
    lot: "",
    block: "",
    buyerType: "buyer",
    addressFreeform: "TBD / Preliminary",
    customerBuyer: null,
    address: "",
    overrideSubdivision: "",
    plan: "",
    elevation: "",
    type: "",
    notes: "",
  },
  createQuoteForm: {
    community: "",
    planOverride: "",
    planElevationOverride: "",
    isSpec: false,
    customerBuyerFreeForm: "",
    addressToBeDetermined: false,
    isPreliminary: false,
    addressType: "knownAddress",
    lot: "",
    block: "",
    buyerType: "buyer",
    addressFreeform: "TBD / Preliminary",
    customerBuyer: null,
    address: "",
    overrideSubdivision: "",
    plan: "",
    elevation: "",
    type: "",
    notes: "",
  },
  templates: [],
  pendingCategories: [],
  pendingItems: [],
  quote: { ...initialQuote },
};

export const UIContext: any = createContext(initialState);

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "ChangeOrderSummaryItems":
      return {
        ...state,
        COSummaryItems: action.payload,
      };
    case "AdminStatus":
      return {
        ...state,
        isAdmin: action.payload,
      };
    case "AvailableJobs":
      return {
        ...state,
        availableJobs: action.payload,
      };
    case "AddPendingCategory":
      // prevent duplicated categories:
      if (
        state.pendingCategories.some(
          (category: HighlandCategoryOption) =>
            category.key === action.payload.key
        )
      ) {
        return state;
      }
      const newPendingCategories = [...state.pendingCategories, action.payload];
      newPendingCategories.sort(
        (a: HighlandCategoryOption, b: HighlandCategoryOption) => {
          if (a.sort < b.sort) return -1;
          if (a.sort > b.sort) return 1;
          return 0;
        }
      );
      const id = Math.random().toString(16).slice(2);
      const possibleCategoryOptions = state.availableOptionCategories.filter(
        (optionCategory: any) =>
          optionCategory.highlandCategory === action.payload.key
      );
      let newCategory = "";
      if (possibleCategoryOptions.length === 1) {
        newCategory = possibleCategoryOptions[0].category;
      }
      if (action.payload.key === "custom") {
        newCategory = "Manual Option";
      }
      const newPendingItems = [
        ...state.pendingItems,
        {
          id: id,
          category: newCategory,
          forcedOption: false,
          highlandCategory: action.payload,
          manualPrice: false,
          option: newCategory,
          optionCode: null,
          description: action.payload.key === "custom" ? "" : null,
          notes: "",
          quantity: 1,
          unitPrice: 0,
          isNew: true,
          totalPrice: 0,
          sortOrder: 1,
        },
      ];
      console.log("Adding category", action.payload);
      return {
        ...state,
        pendingCategories: newPendingCategories,
        pendingItems: newPendingItems,
      };
    case "RemovePendingCategory":
      const categoryIx = state.pendingCategories.findIndex(
        (category: HighlandCategoryOption) =>
          category.key === action.payload.key
      );
      const slicedPendingCategories = [...state.pendingCategories];
      slicedPendingCategories.splice(categoryIx, 1);
      const slicedPendingItems = [...state.pendingItems].filter(
        (pendingItem: OptionTypes) =>
          pendingItem.highlandCategory.key !== action.payload.key
      );
      return {
        ...state,
        pendingCategories: slicedPendingCategories,
        pendingItems: slicedPendingItems,
      };
    case "HighlandCategories":
      return {
        ...state,
        highlandCategories: action.payload,
      };
    case "SelectedJob":
      return {
        ...state,
        selectedJob: action.payload,
      };

    case "SelectedBuyerQuote":
      return {
        ...state,
        selectedBuyerQuote: action.payload,
      };
    case "ChangeOrderPending":
      console.log(
        `updating ChangeOrder pending items from ${action.source}`,
        action.payload
      );
      return {
        ...state,
        changeOrder: {
          ...state.changeOrder,
          status: action.preventStatusChange
            ? state.changeOrder.status
            : "editing",
        },
        pendingItems: action.payload,
      };
    case "ChangeOrderSubmitted":
      return {
        ...state,
        changeOrder: { ...state.changeOrder, submittedItems: action.payload },
      };
    case "ClDialogs":
      return {
        ...state,
        clDialogs: action.payload,
      };
    case "ConstructionManagers":
      return {
        ...state,
        constructionManagers: action.payload,
      };
    case "AvailableBuyerNames":
      return {
        ...state,
        availableBuyerNames: action.payload,
      };

    case "AvailableBuyerQuoteNames":
      return {
        ...state,
        availableBuyerQuoteNames: action.payload,
      };

    case "AvailableOptions":
      return {
        ...state,
        availableOptions: action.payload,
      };
    case "AvailableOptionCategories":
      return {
        ...state,
        availableOptionCategories: action.payload,
      };
    case "AvailableElevations":
      return {
        ...state,
        availableElevations: action.payload,
      };
    case "AvailableAddresses":
      return {
        ...state,
        availableAddresses: action.payload,
      };
    case "AvailablePlans":
      return {
        ...state,
        availablePlans: action.payload,
      };
    case "Communities":
      return {
        ...state,
        communities: action.payload,
      };
    case "ChangeOrder":
      console.log(`updating ChangeOrder from ${action.source}`, action.payload);
      return {
        ...state,
        changeOrder: {
          ...state.changeOrder,
          ...action.payload,
        },
      };
    case "Quote":
      console.log(`updating Quote from ${action.source}`, action.payload);
      return {
        ...state,
        quote: {
          ...state.quote,
          ...action.payload,
        },
      };
    case "QuotePending":
      console.log(
        `updating Quote pending items from ${action.source}`,
        action.payload
      );
      return {
        ...state,
        quote: {
          ...state.quote,
          status: action.preventStatusChange ? state.quote.status : "editing",
        },
        pendingItems: action.payload,
      };
    case "Snackbar":
      return {
        ...state,
        snackbar: action.payload,
      };
    case "CreateQuoteForm":
      return {
        ...state,
        createQuoteForm: {
          ...state.createQuoteForm,
          ...action.payload,
        },
      };
    case "CreateChangeOrderForm":
      return {
        ...state,
        createChangeOrderForm: {
          ...state.createChangeOrderForm,
          ...action.payload,
        },
      };
    case "PendingCategories":
      action.payload.sort(
        (a: HighlandCategoryOption, b: HighlandCategoryOption) => {
          if (a.sort < b.sort) return -1;
          if (a.sort > b.sort) return 1;
          return 0;
        }
      );
      return {
        ...state,
        pendingCategories: action.payload
      };
    case "ResetCreateChangeOrderForm":
      return {
        ...state,
        createChangeOrderForm: initialState.createChangeOrderForm
      };
    case "ResetCreateQuoteForm":
      return {
        ...state,
        createQuoteForm: initialState.createQuoteForm,
      };
    case "ResetChangeOrder":
      console.log("reset Change Order...");
      return {
        ...state,
        changeOrder: { ...initialChangeOrder },
        pendingCategories: [],
        pendingItems: []
      };
    case "ResetChangeOrderPending":
      return {
        ...state,
        changeOrder: { ...state.changeOrder, pendingItems: [] },
      };

    case "Navbar":
      return {
        ...state,
        navbar: action.payload,
      };
    case "AvailableTemplates":
      return {
        ...state,
        templates: action.payload,
      };
    case "SetTemplate":
      return {
        ...state,
        currentTemplate: action.payload,
      };
    default:
      return state;
  }
};

export const UIContextProvider = (props: any) => {
  const [state, dispatch]: any = useReducer(reducer, initialState);

  return (
    <UIContext.Provider value={[state, dispatch]}>
      {props.children}
    </UIContext.Provider>
  );
};
