import { useState, useContext } from "react";
import { UIContext, UIState } from "../../providers/UIProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  deleteChangeOrder,
  GetAddressId
} from "../../apiCalls";
import { dispatchError } from "../../common/fx";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme } from "@mui/material";


const DiscardQuoteConfirmation = () => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [searchParams] = useSearchParams();
  let intentionId = searchParams.get("intentionId");
  const navigate = useNavigate();

  const handleClose = (event: object, reason: string) => {
    if (reason && reason === "backdropClick") return;
    setIsOpen(false);
  };

  const handleDiscard = () => {
    navigate(`../`);
    /*deleteChangeOrder(intentionId || "",
      (res:any) => {
        
        if(state.changeOrder.address.jobID) {
          GetAddressId(
            { jobId: state.changeOrder.address.jobID },
            (res:any) => {
              navigate(`../job-summary/?addressid=${res.data}`);
              dispatch({
                type: "ResetChangeOrder",
              });
              dispatch({
                type: "ResetCreateChangeOrderForm",
              });
            },
            (err:any) => {
              dispatch(dispatchError(err.response.data))
            }
          );
        } else {
          navigate(`../`);
          /*dispatch({
            type: "ResetChangeOrder",
          });
          dispatch({
            type: "ResetCreateChangeOrderForm",
          });
        }
      },
      (err: any) => dispatch(dispatchError(err.response.data))
    )*/
  }

  const handleKeep = () => {
    /*dispatch({
      type: "ResetChangeOrder",
    });
    dispatch({
      type: "ResetCreateChangeOrderForm",
    });*/
    navigate(-1);
  }

  return (
    <>
      <Dialog
        maxWidth="md"
        open={isOpen}
        data-testid="dialog-wrapper"
        onClose={handleClose}
        fullWidth
      >
        <DialogTitle
          data-testid={"dialog-title"}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
          })}
        >
          Before you exit...
        </DialogTitle>
        <DialogContent>
          <Box marginY={(theme) => theme.spacing(2)}>
            <p>The Quote is empty.</p>
            <p>
              To delete this Quote without saving it, click <strong>Discard</strong>.<br />
              To keep this Quote, click <strong>Keep and Exit</strong>.
            </p>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleDiscard}
            variant="contained"
          >
            Discard
          </Button>
          <Button
            color="primary"
            onClick={handleKeep}
            variant="contained"
          >
            Keep and Exit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DiscardQuoteConfirmation;